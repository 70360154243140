<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <label>Email</label>
        <input
          type="email"
          v-model="email" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <label>Password</label>
        <input
          type="password"
          v-model="password" />
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-6 medium-offset-3">
        <button class="button"
          @click="tryLogin">
          Login
        </button>
        &nbsp;
        <router-link to="/forgot-password" class="button">Forgot Password</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Localbase from 'localbase';
import axios from '../axios';

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      failed: false,
      hide: true,
    };
  },
  methods: {
    checkForCredentials() {
      const db = new Localbase('db');
      db.config.debug = false;
      db.collection('users').get().then((users) => {
        if (users[0]) {
          this.$store.commit('useCredentials', users[0]);
          // test if these are still valid
          const testToken = users[0].token;
          axios.post(`/users/testCredentials.json?token=${testToken}`)
            .then(() => {
              this.$router.push('start');
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch('unsetCredentials');
              this.hide = false;
            });
        } else {
          this.hide = false;
        }
      });
    },
    tryLogin() {
      const postData = {};
      postData.email = this.email;
      postData.password = this.password;
      axios.post('/users/login.json', postData)
        .then((response) => {
          this.$store.dispatch('setCredentials', response.data);
          this.$router.push('start');
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    this.checkForCredentials();
  },
};
</script>
